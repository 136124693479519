<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <div class="order-table">
    <!-- <div class="justify-content-between align-items-center my-2 lg-wrap">
      <b-row class="no-gutter">
        <b-col cols="4" xl="4" class="d-flex align-items-center">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="modelFilter.order_no" :placeholder="$t('Search by order code')" />
          </b-input-group>
        </b-col>
        <b-col cols="8" xl="8">
          <div class="d-flex cus-group d-flex justify-content-end">
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="modelFilter.date_from_to"
                class="col form-control miw-150 cus-group-item"
                :config="configTimePicker"
                :placeholder="$t('Ngày tạo')"
              />
            </div>
            <div class="d-flex align-items-center">
              <v-select
                v-model="modelFilter.channel"
                label="name"
                :reduce="x => x.id"
                :options="channels"
                placeholder="Nguồn đơn hàng"
                class="miw-150 cus-group-item"
              />
            </div>
            <b-dropdown
              right
              variant="outline-primary"
              text="Đồng bộ đơn hàng từ sàn"
              class="miw-150"
            >
              <b-dropdown-group
                id="dropdown-group-1"
                header="Đồng bộ đơn hàng"
              >
                <b-dropdown-item @click="SyncOrder('shopee')">Shopee</b-dropdown-item>
                <b-dropdown-item @click="SyncOrder('lazada')">Lazada</b-dropdown-item>
                <b-dropdown-item class="text-mute">Tiktok</b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group
                id="dropdown-group-2"
                header="Đồng bộ trạng thái đơn hàng"
              >
                <b-dropdown-item @click="SyncStatus('shopee')">Shopee</b-dropdown-item>
                <b-dropdown-item @click="SyncStatus('lazada')">Lazada</b-dropdown-item>
                <b-dropdown-item class="text-mute">Tiktok</b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </b-col>
        <div class="pl-1">
          <b-button @click="handleFilter" class="white-space-nowrap">{{ $t('Filter') }}</b-button>
          <b-button @click="handleResetFilter" variant="outline-primary" class="ml-1 white-space-nowrap">{{ $t('Reset') }}</b-button>
        </div>
      </b-row>
    </div> -->
    <div class="justify-content-between align-items-center my-2 lg-wrap">
      <!-- <h3>{{ items.length }} {{ $t('orders') }}</h3> -->
      <div class="d-flex justify-content-between cus-filter-group">
        <div class="d-flex align-items-center flex-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="modelFilter.order_no" :placeholder="$t('Search by order code')" @keyup.enter="handleFilter" />
          </b-input-group>
        </div>
        <div>
          <div class="d-flex cus-group d-flex justify-content-end">
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="modelFilter.date_from_to"
                class="col form-control miw-150 cus-group-item"
                :config="configTimePicker"
                :placeholder="$t('Ngày tạo')"
              />
            </div>
            <div class="d-flex align-items-center">
              <v-select
                v-model="modelFilter.channel"
                label="name"
                :reduce="x => x.id"
                :options="channels"
                placeholder="Nguồn đơn hàng"
                class="miw-150 cus-group-item"
              />
            </div>
            <b-dropdown
              right
              variant="outline-primary"
              text="Đồng bộ đơn hàng từ sàn"
              class="miw-150"
            >
              <b-dropdown-group
                id="dropdown-group-1"
                header="Đồng bộ đơn hàng"
              >
                <b-dropdown-item @click="SyncOrder('shopee')">
                  Shopee
                </b-dropdown-item>
                <b-dropdown-item @click="SyncOrder('lazada')">
                  Lazada
                </b-dropdown-item>
                <b-dropdown-item class="text-mute">
                  Tiktok
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group
                id="dropdown-group-2"
                header="Đồng bộ trạng thái đơn hàng"
              >
                <b-dropdown-item @click="SyncStatus('shopee')">
                  Shopee
                </b-dropdown-item>
                <b-dropdown-item @click="SyncStatus('lazada')">
                  Lazada
                </b-dropdown-item>
                <b-dropdown-item class="text-mute">
                  Tiktok
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activeFilter"
      class="d-flex align-items-center"
      :class="{'my-1': modelFilter.order_no || modelFilter.date_from_to || modelFilter.channel}"
      style="gap: 8px;"
    >
      <b-badge
        v-if="modelFilter.order_no"
        variant="light-dark cursor-pointer"
        @click="modelFilter.order_no = null"
      >
        {{ modelFilter.order_no }}
        <span class="text-danger">X</span>
      </b-badge>
      <b-badge
        v-if="modelFilter.date_from_to"
        variant="light-dark cursor-pointer"
        @click="() => {
          modelFilter.sdate = null
          modelFilter.edate = null
          modelFilter.date_from_to = null
        }"
      >
        {{ modelFilter.date_from_to }}
        <span class="text-danger">X</span>
      </b-badge>
      <b-badge
        v-if="modelFilter.channel"
        variant="light-dark cursor-pointer"
        @click="modelFilter.channel = null"
      >
        {{ channels.find(obj => obj.id === modelFilter.channel).name }}
        <span class="text-danger">X</span>
      </b-badge>
    </div>
    <b-card no-body class="head mb-1">
      <b-row>
        <b-col cols="5">
          {{ $t('Products') }}
        </b-col>
        <b-col class="text-center">
          {{ $t('Total') }}
        </b-col>
        <b-col class="text-center">
          {{ $t('Status') }}
        </b-col>
        <b-col class="text-center">
          {{ $t('Transporters') }}
        </b-col>
        <!-- <b-col>
        </b-col> -->
      </b-row>
    </b-card>
    <div v-if="value.list.length == 0" class="text-center">
      {{ $t('Order not found') }}
    </div>
    <b-card
      v-for="order in value.list" :key="order.id"
      class="order-card mb-1"
    >
      <template #header>
        <div class="card-header__left d-flex">
          <feather-icon icon="UserIcon" size="20" style="margin-right: 6px;" />
          <strong class="name">{{ order.fullname }}</strong>
        </div>
        <div class="card-header__right">
          <img v-if="order.type === 0" width="18" height="18" src="/website.png" alt="Web">
          <img v-if="order.type === 1" width="18" height="18" src="/logo_shopee.png" alt="Shopee">
          <img v-if="order.type === 2" width="18" height="18" src="/lazada.png" alt="Lazada">
          <img v-if="order.type === 3" width="18" height="18" src="/tiktok.png" alt="Tiktok">
          <strong class="text mx-1" style="font-size: 12px">{{ $t('Order ID') }}: {{ order.order_no }}</strong>
          <feather-icon
            class="cursor-pointer"
            icon="CopyIcon"
            @click="doCopy(order.order_no)"
          />
        </div>
      </template>
      <b-row
        class="align-items-center"
      >
        <b-col md="5" style="display: grid; row-gap: 12px;">
          <b-row
            v-for="item in order.list"
            :key="item.id"
            class="align-items-center"
          >
            <b-col md="8" class="d-flex align-items-center">
              <img
                :src="item.avatar_warehouse || item.avatar_product"
                :alt="item.name_product"
                height="60"
                width="60"
                class="mr-1"
              >
              <strong class="name">{{ item.name_product || item.product_nm_item }}</strong>
            </b-col>
            <b-col class="text-center">
              x{{ item.quantity }}
            </b-col>
            <!-- <b-col class="text-center">
              <div>{{ unitFormatOriginal(item.price) }}</div>
              <small>{{ order.payment_type_text }}</small>
            </b-col> -->
          </b-row>
        </b-col>
        <b-col class="text-center">
          <!-- <small>{{ order.payment_method }}</small> -->
          <div>{{ unitFormatOriginal(order.totalF) }}</div>
          <small>{{ order.payment_type_text }}</small>
        </b-col>
        <b-col class="text-center">
          <!-- <div>
            <small>{{ order.payment_status_text }}</small>&nbsp;
            <feather-icon
              v-if="order.cancel_by"
              v-b-tooltip.hover.top="`Canceled by ${order.cancel_by}`"
              icon="HelpCircleIcon"
              class="text-warning"
            />
          </div> -->
          <small>{{ $t(order.status) }}</small>
        </b-col>
        <b-col class="text-center">
          <b-badge pill variant="secondary">
            <small>{{ order.shipping_status_text }}</small>
          </b-badge>
          <feather-icon
            v-if="order.shipping_order_code"
            class="cursor-pointer ml-1"
            icon="PrinterIcon"
            @click="handlerPrint(order.param_ghn ? order.param_ghn : order.param_ghtk, order.shipping_status)"
          />
          <small class="d-block">{{ order.param_ghn ? order.param_ghn : order.param_ghtk }}</small>
        </b-col>
        <b-col class="text-right">
          <router-link :to="`${link_detail}/${order.id}`">
            {{ $t('Detail') }}
          </router-link>
        </b-col>
      </b-row>
      <!-- <template #footer>
        <span>{{ $t('Total Price') }}:&nbsp;</span>
        <strong class="price-total">{{ unitFormatOriginal(order.total) }}</strong>
      </template> -->
    </b-card>

    <b-row v-if="paging">
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{ $t('Per page') }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="value.param.limit"
            size="sm"
            :options="pageOptions"
            class="w-75"
            @change="handlerChangePerPage(perPage)"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" sm="8" class="my-1">
        <b-pagination
          v-model="value.param.page"
          :total-rows="value.param.total"
          :per-page="value.param.limit"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable vue/prop-name-casing */
/* eslint-disable operator-linebreak */
/* eslint-disable object-shorthand */
import {
  // BAvatar,
  BPagination,
  // BTable,
  BRow,
  BCol,
  // BButton,
  // BLink,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  // BFormRating,
  // BFormCheckbox,
  BFormInput,
  BInputGroupPrepend,
  BBadge,
  BCard,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import LanguageSelect from '@/views/components/language-select/index.vue'
// import OrderStatus from '@/views/components/order-status/index.vue'
// import PaymentStatus from '@/views/components/order-status/payment_status.vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import vSelect from 'vue-select'

export default {
  components: {
    // BButton,
    // BLink,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    // LanguageSelect,
    // BAvatar,
    BPagination,
    // BTable,
    BRow,
    BCol,
    // BFormRating,
    // BFormCheckbox,
    BFormInput,
    BInputGroupPrepend,
    // OrderStatus,
    // PaymentStatus,
    BCard,
    BBadge,
    vSelect,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    link_detail: {
      type: String,
      default: 'edit',
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    per_page: {
      type: Number,
      default: 10,
    },
    paging: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      languageList: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [
        5,
        10,
        20,
        this.per_page !== 5 && this.per_page !== 10 && this.per_page !== 20
          ? this.per_page
          : 30,
      ],
      perPage: 10,
      totalRows: 100,
      itemsShow: [],
      currentPage: 1,
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      orderStatus: {},
      configTimePicker: {
        minDate: null,
        maxDate: null,
        mode: 'range',
        dateFormat: 'Y/m/d',
        // defaultDate: [new Date().fp_incr(-15), new Date()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.modelFilter.sdate = start.toLocaleDateString('en-ZA')
            this.modelFilter.edate = end.toLocaleDateString('en-ZA')
          }
        },
      },
      channels: [
        {
          id: null,
          name: 'Nguồn đơn hàng',
        },
        {
          id: 4,
          name: 'Website',
        },
        {
          id: 1,
          name: 'Shopee',
        },
        {
          id: 2,
          name: 'Lazada',
        },
        {
          id: 3,
          name: 'Tiktok',
        },
      ],
      modelFilter: {
        channel: null,
        date_from_to: null,
        sdate: null,
        edate: null,
        order_no: null,
      },
      activeFilter: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    paymentStatus() {
      return [
        { text: 'COD', value: 'COD' },
        { text: 'Transfer money', value: 'Transfer money' },
        // { text: 'Paid', value: 'Paid' },
        // { text: 'Cancel', value: 'Cancel' },
      ]
    },
  },
  watch: {
    per_page(val) {
      if (val) {
        this.perPage = val
      }
    },
    '$route.query.order_no'() {
      this.modelFilter.order_no = this.$route.query.order_no
    },
    '$route.query.channel'() {
      this.modelFilter.channel = Number(this.$route.query.channel)
    },
    '$route.query.date_from'() {
      this.modelFilter.date_from_to = `${this.$route.query.date_from} đến ${this.$route.query.date_to}`
    },
    '$route.query.date_to'() {
      this.modelFilter.date_from_to = `${this.$route.query.date_from} đến ${this.$route.query.date_to}`
    },
    'modelFilter.order_no'() {
      if (this.modelFilter.order_no === null) this.handleFilter()
    },
    'modelFilter.channel'() {
      this.handleFilter()
    },
    'modelFilter.edate'() {
      this.handleFilter()
    },
  },
  async mounted() {
    this.languageList = await this.loadLanguage()

    if (this.$route.query.order_no) this.modelFilter.order_no = this.$route.query.order_no
    if (this.$route.query.channel) this.modelFilter.channel = Number(this.$route.query.channel)
    if (this.$route.query.date_from && this.$route.query.date_to) this.modelFilter.date_from_to = `${this.$route.query.date_from} đến ${this.$route.query.date_to}`

    this.loadData()
  },
  created() {
    // this.loadData()
  },
  methods: {
    loadData() {
      this.currentPage =
      this.$route.query.page && this.$route.query.page !== undefined
        ? this.$route.query.page
        : 1
      this.perPage =
      this.$route.query.per_page && this.$route.query.per_page !== undefined
        ? this.$route.query.per_page
        : this.per_page
      this.totalRows = this.items.length
    },
    handlerChangePage(page) {
      this.$router.replace({ query: { page, per_page: this.perPage } })
    },
    handlerChangePerPage(perPage) {
      this.$router.replace({ query: { per_page: perPage } })
    },
    SyncOrder(channel) {
      this.$emit('SyncOrder', channel)
    },
    SyncStatus(channel) {
      this.$emit('SyncStatus', channel)
    },
    handleFilter() {
      const params = {}
      if (this.modelFilter.order_no) params.order_no = this.modelFilter.order_no
      if (this.modelFilter.channel) params.channel = this.modelFilter.channel
      if (this.modelFilter.sdate) params.date_from = this.modelFilter.sdate
      if (this.modelFilter.edate) params.date_to = this.modelFilter.edate
      this.$router.replace({
        query: params,
      }).catch(() => {})
      this.$emit('handleFilter', this.modelFilter)
      this.activeFilter = true
    },
    handleResetFilter() {
      this.modelFilter = {
        channel: null,
        date_from_to: null,
        sdate: null,
        edate: null,
        order_no: null,
      }
      this.$router.replace({})
    },
    handlerDelete(id) {
      this.$emit('handlerDelete', id)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    PaginationPerPage(data) {
      this.itemsShow = data
    },
    onPageChange(page) {
      this.currentPage = page
    },
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    handlerPrint(data, status) {
      this.$emit('handlerPrint', data, status)
    },
  },
}
</script>
<style lang="sass">
.order-table
  .cus-group
    .vs__dropdown-toggle
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      border-right: 0

    .vs__dropdown-toggle,
    .btn-outline-primary
      border-top-left-radius: 0
      border-bottom-left-radius: 0

    .form-control
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      border-right: 0

      &::placeholder
        color: #6e6b7b

    .btn-outline-primary
      border-left: 0
      color: #6e6b7b
      font-weight: normal
      border-color: #d8d6de !important

  .cus-filter-group
    gap: 0 16px

    .flex-1
      flex: 1

  .miw-150
    min-width: 200px

  .lg-wrap
    @media screen and (max-width: 1399px)
      flex-wrap: wrap
.head
  padding: 8px 20px
  position: sticky
  top: 62px
  z-index: 1
  background: #e1e1e1
  box-shadow: none
  .row
    margin-left: -4px
    margin-right: -4px
    row-gap: 8px
  [class*="col"]
    padding-left: 4px
    padding-right: 4px
.order-card
  border: 1px solid rgba(34, 41, 47, 0.125)
  .card
    &-header
      background: var(--order-card-header-bg, #fafafa)
      padding: 8px 20px

      + .card-body
        padding-top: 1.5rem

    &-body
      display: grid
      gap: 16px
      padding: 1rem

      .row
        margin-left: -4px
        margin-right: -4px
        row-gap: 8px

      [class*="col"]
        padding-left: 4px
        padding-right: 4px

    &-footer
      text-align: right
      padding: 8px 20px

      .price-total
        font-size: 20px
        color: red

.dark-layout
  --order-card-header-bg: #f5f6f8
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.white-space-nowrap {
  white-space: nowrap;
}
.gap-1 {
  gap: 1.5rem;
}
</style>
